@mixin resize-to($device) {
    @if $device == phone{
        @media all and (max-width:700px){
            @content;
        }
    }
    @elseif $device == desktop{
        @media all and (min-width:701px){
            @content;
        }
    }
}
@mixin absolute($position){
    @include position(absolute, $position);
};
@mixin fixed($position){
    @include position(fixed, $position);
};
@mixin box-shadow($shadow:default){
    @if $shadow == default{
        box-shadow:0 3px 3px 0 rgba(0,0,0,0.3);
    }
    @else{
        box-shadow:$shadow;
    }
}
@mixin border-radius($radius:2px) {
    @include border-top-radius($radius);
    @include border-bottom-radius($radius);
}
@mixin clear-after {
    &:after{
        content:'';
        clear:both;
        display:block;
    }
}
@mixin page{
    width:100%;
    max-width:960px;
    margin:0 auto;
}

$tan:#F2E5B3;
$brown:#B86A3F;
$lightBrown:#E8C461;
$black:#000;
$white:#FFF;
$red:#DA251D;
$textRed:#F31713;
$yellow:#FFD300;
$gray:#333;
$lightGray:#63656A;
$green:#00B16A;

@import "libs/includes";
@import "libs/reset";
body {
    background: $white;
    font-family: 'Lato',sans-serif;
    background: $tan;
}
.action-call {
    background: $yellow;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    padding: 8px 0;
    @include resize-to(phone) {
        padding: 8px;
    }
    a {
        color: $textRed;
        font-weight: 700;
        font-size: 18px;
        text-decoration: none;
    }
}
.header-wrap {
    background: $white;
}
.header {
    @include page;
    padding: 20px 0 30px;
    .location {
        text-transform: uppercase;
        font-weight: bold;
        color: $lightGray;
        text-align: center;
        padding-top: 8px;
        margin-left: 50px;
        width: 230px;
        font-size: 12px;
        @include resize-to(phone) {
            width: 100%;
            margin: 0;
        }
    }
}
.social-icons {
    font-size: 18px;
    color: $brown;
    text-decoration: none;
    padding: 0 20px;
    float: right;
    @include resize-to(phone) {
        display: none;
    }
    li {
        margin-right: 18px;
        float: left;
        a {
            color: inherit;
            cursor: pointer;
            text-decoration: inherit;
            &.email {
                color: $textRed;
            }
        }
        &:last-of-type {
            margin: 0;
        }
    }
}
.logo {
    margin-left: 50px;
    @include resize-to(phone) {
        display: block;
        margin: 0 auto;
        width: 229px;
    }
}
.navigation {
    @include resize-to(phone) {
        display: none;
    }
    float: right;
    a {
        text-decoration: none;
        font-weight: bold;
        font-size: 18px;
        margin-right: 30px;
        color: $gray;
        &:hover,
        &.current-nav {
            color: $textRed;
        }
    }
}
.mobile-navigation-button {
    @include absolute(74px 10px null null);
    @include border-radius(50%);
    border: solid 4px $red;
    color: $red;
    font-size: 24px;
    line-height: 1.5em;
    @include size(45px);
    text-align: center;
    z-index: 50;
    @include resize-to(desktop) {
        display: none;
    }
}
.mobile-navigation {
    @include absolute(0 null null 0);
    width: 100%;
    background: $brown;
    @include box-shadow;
    z-index: 100;
    .mobile-social-icons {
        background: $gray;
        text-align: center;
        a {
            padding: 16px 24px;
            font-size: 24px;
            color: $white;
            text-decoration: none;
        }
    }
    .mobile-links {
        text-align: center;
        a {
            display: block;
            color: $white;
            text-decoration: none;
            text-transform: uppercase;
            padding: 16px 0;
        }
    }
}
.about {
    @include page;
    padding: 100px 0;
    @include resize-to(phone) {
        padding: 0;
    }
    @include clear-after;
    .image-column {
        @include resize-to(desktop) {
            width: 40%;
            float: left;
        }
        .image-of-jerry {
            position: relative;
            background: url("../imgs/jerry.jpg") center no-repeat;
            background-size: cover;
            @include border-radius(50%);
            @include size(278px);
            margin: 0 auto;
            @include resize-to(phone) {
                margin-top: 20px;
            }
        }
        .about-quote {
            @include absolute(70px 30px null null);
            color: $white;
            width: 120px;
        }
    }
    .text-column {
        @include resize-to(desktop) {
            padding: 0 20px;
            width: 60%;
            float: left;
        }
        @include resize-to(phone) {
            padding: 24px;
        }
    }
    .about-title {
        font-size: 24px;
        color: $textRed;
        font-weight: bold;
        padding-bottom: 14px;
    }
    .about-sub-title {
        padding: 6px 0;
        color: $textRed;
        font-weight: bold;
    }
    .signature {
        font-family: 'Oleo Script Swash Caps';
        text-align: right;
        font-size: 20px;
        padding-top: 32px;
    }
}
.chipmunk {
    position: relative;
    background: url("../imgs/chipmunk.png") 0 0 no-repeat;
    @include size(152px 122px);
    margin: 0 auto -8px;
    z-index: 10;
}
.benefits-wrap {
    background: $brown;
    .benefits {
        @include page;
        @include clear-after;
        color: $white;
        text-transform: capitalize;
        padding: 18px 0;
        font-size: 15px;
        @include resize-to(phone) {
            width: 200px;
        }
        .bullet {
            color: $black;
        }
        li {
            @include resize-to(phone) {
                padding: 8px 0;
            }
            @include resize-to(desktop) {
                margin-left: 10px;
                float: left;
                &:first-of-type {
                    margin-left: 19px;
                }
            }
        }
    }
}
.service-loc-wrap {
    background: url("../imgs/service-locations.jpg") center no-repeat;
    background-size: cover;
    color: $white;
    .service-loc {
        @include page;
        padding: 70px 0 40px;
        @include resize-to(phone) {
            padding: 20px 14px;
        }
        h1 {
            font-size: 30px;
            @include resize-to(phone) {
                font-size: 20px;
            }
            text-align: center;
        }
        ul {
            font-size: 18px;
            width: 70%;
            margin: 10px auto;
            padding: 12px 0;
            @include clear-after;
            @include resize-to(phone) {
                width: 100%;
                margin: 0;
            }
        }
        li {
            padding: 18px 0;
            width: 20%;
            float: left;
            @include resize-to(phone) {
                width: 50%;
                padding: 8px 0;
                text-align: center;
            }
        }
        h3 {
            margin-top: 50px;
            font-size: 20px;
            text-align: center;
            @include resize-to(phone) {
                padding: 0 14px;
            };
        }
    }
}
.testimonials-wrap {
    background: $white;
    .testimonials {
        @include page;
        max-width: 600px;
        height: 380px;
        text-align: center;
        padding: 60px 0;
        @include resize-to(phone) {
            padding: 14px 24px;
        }
    }
    .quote {
        border: solid 6px $yellow;
        color: $yellow;
        @include size(85px);
        margin: 0 auto;
        @include border-radius(50%);
        text-align: center;
        font-size: 96px;
        line-height: 116px;
        @include resize-to(phone) {
            @include size(60px);
            font-size: 76px;
            line-height: 85px;
        }
    }
    .testimonial {
        position: relative;
        font-size: 20px;
        color: $gray;
        font-style: italic;
        li {
            @include absolute(50px null null 0);
            @include resize-to(phone) {
                top: 18px;
            }
        }
    }
}
.call-to-action-wrap {
    background: url("../imgs/windshield-chip.jpg") center no-repeat;
    background-size: cover;
    height: 400px;
    padding-top: 160px;
    .call-to-action-outer {
        background: $red;
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        padding: 10px;
    }
    .call-to-action-inner {
        border: solid 3px $tan;
        text-align: center;
        padding: 14px 0;
        font-size: 20px;
        color: $white;
        line-height: 1.8em;
        a {
            color: $white;
            text-decoration: none;
        }
    }
}
.faq-wrap {
    padding-top: 20px;
    background: $tan;
    .faq-title {
        text-align: center;
        color: $red;
        font-size: 34px;
        font-weight: bold;
        font-style: italic;
    }
    .faq-button {
        display: block;
        width: 260px;
        margin: 0 auto;
        background: $yellow;
        text-align: center;
        color: $black;
        text-decoration: none;
        padding: 12px 0;
    }
    .faq {
        @include page;
        @include clear-after;
        padding: 0 24px;
        .faq-column {
            padding: 24px;
            width: 50%;
            color: $gray;
            float: left;
            @include resize-to(phone) {
                width: 100%;
                float: none;
            }
            h2,
            p {
                &:before {
                    font-size: 32px;
                    color: $brown;
                    padding: 8px;
                    font-style: italic;
                    font-weight: bold;
                }
            }
            h2 {
                font-size: 20px;
                font-weight: bold;
                color: $black;
                &:before {
                    content: 'q:';
                }
            }
            p {
                padding-top: 10px;
                &:before {
                    content: 'a:';
                }
            }
        }
    }
}
.faq-page {
    padding: 60px 0;
}
.contact-wrap {
    @include page;
    padding: 100px 0;
    @include clear-after;
    .title {
        text-align: center;
        font-size: 42px;
        color: $brown;
        font-weight: bold;
        font-style: italic;
        padding-bottom: 36px;
    }
    .phone-column {
        text-align: center;
        padding: 14px;
        .phone-number {
            font-size: 32px;
            color: $red;
        }
        .phone-text {
            padding-top: 20px;
        }
        @include resize-to(desktop) {
            text-align: right;
            width: 50%;
            padding: 14px 14px 14px 190px;
            float: left;
        }
    }
    .contact-form {
        border-left: solid 1px $lightBrown;
        padding: 14px;
        width: 100%;
        max-width: 330px;
        float: left;
        .error {
            color: $red;
            font-size: 14px;
            font-style: italic;
            &:before {
                content: '* ';
            }
        }
    }
    .contact-copy {
        display: block;
        clear: both;
        font-size: 10px;
        color: $brown;
        padding-top: 8px;
    }
}
fieldset {
    margin-bottom: 8px;
    &:last-of-type {
        margin-bottom: 0;
    }
}
label {
    color: $brown;
    font-style: italic;
    margin: 0 4px 2px;
    display: block;
}
input[type="text"],
textarea {
    display: block;
    width: 100%;
    font: inherit;
    padding: 6px 10px;
    border: solid 1px #ccc;
    @include border-radius;
}
textarea {
    height: 100px;
}
button {
    margin-top: 8px;
    background: $green;
    border: none;
    font: inherit;
    padding: 12px 0;
    width: 165px;
    color: $white;
    cursor: pointer;
    font-weight: 300;
    float: right;
    @include border-radius(4px);
    @include box-shadow(0 0 0 0 rgba(0,0,0,0));
    @include transition(box-shadow $ease-in-out-cubic 150ms);
    &:hover {
        @include box-shadow;
    }
}
.contact-success {
    @include fixed(10% null null 5%);
    width: 90%;
    @include resize-to(desktop) {
        @include fixed(50% null null 50%);
        width: 400px;
        margin-top: -200px;
        margin-left: -200px;
    }
    background: $white;
    @include box-shadow(0 0 50px 20px rgba(0,0,0,0.3));
    @include border-radius(6px);
    z-index: 150;
    .title {
        text-transform: uppercase;
        color: $white;
        font-size: 24px;
        background: $green;
        padding: 8px 0;
        @include border-top-radius(6px);
    }
    .message {
        padding: 24px;
        color: $gray;
        line-height: 1.25em;
    }
}
.image-sources {
    .image-source {
        @include page;
        padding:42px;
        .image {
            @include size(140px);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            float:left;
        }
        .link{
            padding:2px 32px;
            float:left;
            h4{
                font-size:18px;
                color:$red;
            }
            a{
                display:block;
                padding-top:12px;
                text-decoration:none;
                color:$brown;
            }
        }
        @include clear-after;
    }
}
.chip-chip-away-vehicle {
    background: url("../imgs/vehicle.jpg") center no-repeat;
    background-size: cover;
    height: 400px;
    @include resize-to(phone) {
        height: 200px;
    }
}
.footer-wrap {
    background: $black;
    color: $gray;
    padding: 22px;
    text-align: center;
    font-size: 14px;
    a{
        color:inherit;
    }
}
